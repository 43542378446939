import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Typography, Divider } from '@material-ui/core'
import classNames from 'classnames'
import get from 'lodash/get'

const useStyles = makeStyles(theme => ({
  dividerCta: {
    display: 'block',
    alignSelf: 'center',
  },
  headline: {
    marginRight: 'auto',
    color: theme.palette.common.white,
    '& h4': {
      fontWeight: theme.typography.fontWeightLight,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      paddingBottom: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '80%',
      textAlign: 'left',
      paddingBottom: '0px',
    },
  },
  boxCta: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  containerCta: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    '& button': {
      minWidth: '100px',
      padding: '4px 12px',
    },
    '& a': {
      padding: '4px 8px',
    },
  },
  containerCtaLeft: {
    textAlign: 'left',
    fontWeight: theme.typography.fontWeightBold,
    '& button': {
      minWidth: '100px',
      padding: '4px 12px',
    },
    '& a': {
      padding: '4px 8px',
    },
  },
}))

const CtaModule = props => {
  const classes = useStyles(props)
  const { cta } = props.blok
  const alignmentButton = get(cta, '[0].alignmentButton', false)
  const isRightAlignmentButton = alignmentButton === 'right'
  const isLeftAlignmentButton = alignmentButton === 'left'

  return (
    <SbEditable content={props.blok}>
      <Box
        className={classNames({
          [classes.boxCta]: isRightAlignmentButton,
        })}
      >
        {isRightAlignmentButton && (
          <Box m={'auto'} className={classes.headline}>
            <Divider className={classes.dividerCta} />
          </Box>
        )}

        <Box
          className={classNames({
            [classes.containerCta]: isRightAlignmentButton,
            [classes.containerCtaLeft]: isLeftAlignmentButton,
          })}
          align={alignmentButton}
        >
          {renderBloks(cta)}
        </Box>
      </Box>
    </SbEditable>
  )
}

export default CtaModule
